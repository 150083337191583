import { lazy } from 'react';
import type { Route } from '../types/route.types';

// Lazy loading des composants
const Dashboard = lazy(() => import('../pages/Dashboard').then(module => ({ default: module.Dashboard })));
const Users = lazy(() => import('../pages/Users').then(module => ({ default: module.Users })));
const Notifications = lazy(() => import('../pages/Notifications').then(module => ({ default: module.Notifications })));
const CVPage = lazy(() => import('../pages/CVPage').then(module => ({ default: module.CVPage })));
const Tokens = lazy(() => import('../pages/Tokens').then(module => ({ default: module.Tokens })));
const Referrals = lazy(() => import('../pages/Referrals').then(module => ({ default: module.Referrals })));
const Transactions = lazy(() => import('../pages/Transactions').then(module => ({ default: module.Transactions })));
const Updates = lazy(() => import('../pages/Updates').then(module => ({ default: module.Updates })));
const Bugs = lazy(() => import('../pages/Bugs').then(module => ({ default: module.Bugs })));
const Badges = lazy(() => import('../pages/Badges').then(module => ({ default: module.Badges })));

export const routes: Route[] = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    icon: "HomeIcon"
  },
  {
    path: "/users",
    name: "Utilisateurs",
    component: Users,
    icon: "UsersIcon"
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    icon: "BellIcon"
  },
  {
    path: "/cv",
    name: "CV",
    component: CVPage,
    icon: "FileTextIcon"
  },
  {
    path: "/tokens",
    name: "Jetons",
    component: Tokens,
    icon: "CoinsIcon"
  },
  {
    path: "/badges",
    name: "Badges",
    component: Badges,
    icon: "AwardIcon"
  },
  {
    path: "/referrals",
    name: "Parrainages",
    component: Referrals,
    icon: "UsersIcon"
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    icon: "CreditCardIcon"
  },
  {
    path: "/updates",
    name: "Mises à jour",
    component: Updates,
    icon: "RefreshCwIcon"
  },
  {
    path: "/bugs",
    name: "Bugs",
    component: Bugs,
    icon: "BugIcon"
  }
] as const; 