import React, { createContext, useContext, useState, useEffect } from 'react';

interface EnvContextType {
  isDev: boolean;
  toggleEnv: () => void;
}

const EnvContext = createContext<EnvContextType | undefined>(undefined);

export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isDev, setIsDev] = useState(() => {
    // Récupérer l'état initial depuis le localStorage ou utiliser true par défaut
    const savedEnv = localStorage.getItem('isDev');
    return savedEnv ? JSON.parse(savedEnv) : true;
  });

  // Sauvegarder l'état dans le localStorage à chaque changement
  useEffect(() => {
    localStorage.setItem('isDev', JSON.stringify(isDev));
  }, [isDev]);

  const toggleEnv = () => {
    setIsDev((prev: boolean) => !prev);
  };

  return (
    <EnvContext.Provider value={{ isDev, toggleEnv }}>
      {children}
    </EnvContext.Provider>
  );
};

export const useEnvStore = () => {
  const context = useContext(EnvContext);
  if (context === undefined) {
    throw new Error('useEnvStore must be used within an EnvProvider');
  }
  return context;
}; 