import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Home, Users, Bell, FileText, Coins, Users2, Receipt, RefreshCw, Bug, LogOut, Award } from "lucide-react";
import { useAuth } from "../contexts/AuthContext";
import { cn } from "../lib/utils";
import { motion } from "framer-motion";

export const menuItems = [
  {
    icon: Home,
    label: "Accueil",
    path: "/",
    description: "Tableau de bord principal"
  },
  {
    icon: Users,
    label: "Utilisateurs",
    path: "/users",
    description: "Gestion des utilisateurs"
  },
  {
    icon: Bell,
    label: "Notifications",
    path: "/notifications",
    description: "Centre de notifications"
  },
  {
    icon: FileText,
    label: "CV",
    path: "/cv",
    description: "Gestion des CV"
  },
  {
    icon: Coins,
    label: "Jetons",
    path: "/tokens",
    description: "Gestion des jetons"
  },
  {
    icon: Award,
    label: "Badges",
    path: "/badges",
    description: "Gestion des badges"
  },
  {
    icon: Users2,
    label: "Parrainage",
    path: "/referrals",
    description: "Programme de parrainage"
  },
  {
    icon: Receipt,
    label: "Transactions",
    path: "/transactions",
    description: "Historique des transactions"
  },
  {
    icon: RefreshCw,
    label: "Mise à jour",
    path: "/updates",
    description: "Mises à jour du système"
  },
  {
    icon: Bug,
    label: "Signalement",
    path: "/bugs",
    description: "Gestion des bugs"
  }
];

interface SidebarProps {
  onCloseMobileMenu: () => void;
}

export const Sidebar = ({ onCloseMobileMenu }: SidebarProps) => {
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
    onCloseMobileMenu();
  };

  return (
    <motion.div 
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.2 }}
      className="h-full min-h-screen bg-gray-900 text-white flex flex-col"
    >
      <div className="p-4 border-b border-gray-800">
        <motion.h1 
          className="text-xl font-bold"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2 }}
        >
          Ariah Dashboard
        </motion.h1>
      </div>

      <nav className="flex-1 mt-4" role="navigation" aria-label="Menu principal">
        {menuItems.map((item, index) => (
          <Link
            key={item.path}
            to={item.path}
            className={cn(
              "flex items-center gap-3 px-4 py-3 hover:bg-gray-800 transition-colors relative group",
              location.pathname === item.path && "bg-gray-800 border-r-4 border-primary"
            )}
            onClick={onCloseMobileMenu}
            role="menuitem"
            aria-current={location.pathname === item.path ? "page" : undefined}
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ delay: index * 0.1 }}
            >
              <item.icon size={20} aria-hidden="true" />
            </motion.div>
            <span>{item.label}</span>

            {/* Tooltip */}
            <div className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-xs rounded hidden group-hover:block whitespace-nowrap">
              {item.description}
            </div>
          </Link>
        ))}
      </nav>

      <button
        onClick={handleLogout}
        className="flex items-center gap-3 px-4 py-4 hover:bg-red-900/20 transition-colors text-red-400 mt-auto border-t border-gray-800 group"
        aria-label="Se déconnecter"
      >
        <LogOut size={20} aria-hidden="true" />
        <span>Déconnexion</span>
        
        {/* Tooltip déconnexion */}
        <div className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-xs rounded hidden group-hover:block whitespace-nowrap">
          Se déconnecter de l'application
        </div>
      </button>
    </motion.div>
  );
};